import React from "react";
import TopHeader from "./topHeader";
import Navbar from "./navbar";

const Header = ({menu}) => {
    return(
        <div className="headerWrapper">
            <div className="header">
                <TopHeader />
                <Navbar menuItems={menu} />
            </div>
        </div>
    );
};

export default Header;