import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import axios from "axios";
import { google_Api_Key } from "../../Helper";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

const Map = ({ height, propertyListing }) => {
  const [center, setCenter] = useState(null);
  const mapRef = useRef(null); 
  const location = useLocation();
 
  const zipCode = useSelector((state) =>
    state?.persistedReducer?.zip?.zip || state?.persistedReducer?.zip || ""
  );
  const officeZip = useSelector((state) => state?.persistedReducer?.webData?.zip);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: google_Api_Key,
  });
  
  useEffect(() => {

    if ( zipCode || officeZip  && typeof zipCode === "string" &&zipCode.trim() !== "") {
     

      const address =
        location?.pathname === "/contact-us"
          ? officeZip
          : location?.state?.searchTerm || zipCode;

      axios
        .get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
            address
          )}&key=${google_Api_Key}`
        )
        .then((response) => {
          if (response.data.results.length > 0) {
            const location = response.data.results[0].geometry.location;
            setCenter({ lat: location.lat, lng: location.lng });
          } else {
            console.error("No results found for this zip code.");
          }
        })
        .catch((error) => console.error("Error in geocoding:", error));
    }
  }, [zipCode,officeZip]);
  const fitToBounds = () => {
    if (mapRef.current && propertyListing?.length > 0) {
      const bounds = new window.google.maps.LatLngBounds();
      propertyListing.forEach((location) => {
        if (location.latitude && location.longitude) {
          bounds.extend({
            lat: parseFloat(location.latitude),
            lng: parseFloat(location.longitude),
          });
        }
      });
      mapRef.current.fitBounds(bounds);
    }
  };

  useEffect(() => {
    if (propertyListing?.length > 0) {
      fitToBounds(); // Adjust the map bounds whenever property data changes
    }
  }, [propertyListing]);

  if (!isLoaded) {
    return <div>Loading map...</div>;
  }

  return (
    <>
      {location?.pathname === '/contact-us' ?
    <div className="mapWrapper" style={{ height }}>
      {center ? (
        <GoogleMap
          mapContainerStyle={{ width: "100%", height: "100%" }}
          center={center}
          zoom={5}
        >
            </GoogleMap>
          ) : (
            <div>Loading map for the zip code...</div>
          )}
        </div> :
        <div className="mapWrapper" style={{ height }}>
          <GoogleMap mapContainerStyle={{ width: "100%", height: "100%" }} zoom={5} center={center}  onLoad={(map) => (mapRef.current = map)}>
          {propertyListing?.map((location, index) => {
            if (location.latitude && location.longitude) {
              return (
                <Marker
                  key={index}
                    position={{ lat: parseFloat(location.latitude), lng: parseFloat(location.longitude) }}
                  />)
            }
          })}
        </GoogleMap>
        </div>}
    </>


  );
};

export default Map;
