import React, { useEffect, useState } from "react";
import { Modal, Form, Input, Table, Button, message, Select } from "antd";
import { useGetUserDetailQuery } from "../../store/getSlice";
import { useAddAddressDetailsMutation, useDeleteAddressDetailsMutation, useUpDateAddressDetailsMutation } from "../../store/apiSlice";
import { Country, State, City } from 'country-state-city';
import swal from 'sweetalert'
const { Option } = Select;
const BillingInformation = () => {
    const [form] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [addressId, setAddressId] = useState()
    const [formType, setFormType] = useState()
    const countries = Country.getAllCountries();
    const [addAddressDetails] = useAddAddressDetailsMutation()
    const [upDateAddressDetails] = useUpDateAddressDetailsMutation()
    const [deleteAddressDetails] = useDeleteAddressDetailsMutation()
    const { data: userDetail, refetch } = useGetUserDetailQuery()
    const [messageApi, contextHolder] = message.useMessage();
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [isSaving, setIsSaving] = useState(false)
    const [isEditSaving, setIsEditSaving] = useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const state = State?.getStatesOfCountry(selectedCountry);
    const Cities = City.getCitiesOfState(
        selectedCountry,
        selectedState
    );// State to hold the search query for states


    const pageSize = 5;
    const success = (message) => {
        messageApi.open({
            type: 'success',
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: 'error',
            content: message,
        });
    };

    useEffect(() => {
        // Fetch countries and set options
        const countries = Country.getAllCountries();
        const countriesOptions = countries.map((item, index) => ({
          key: index,
          label: item.name,
          value: item.isoCode,
        }));
    
      }, []);
   
   
      const handleCountryChange = (isoCode) => {
          setSelectedCountry(isoCode.key);
      };
   
      // Handle State selection and send ISO code
      const handleStateChange = (isoCode) => {
          setSelectedState(isoCode.key);
   
      };
   
      // Handle City selection
      const handleCityChange = (cityName) => {
          setSelectedCity(cityName);
      };


    const showModal = (addressData) => {
        setIsModalOpen(true);
        if (addressData) {
            form.setFieldsValue(addressData);
        } else {
            form.resetFields();
        }
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const onFinish = async (values) => {

        let data = {
            address: values.address,
            billing_zip: values?.billing_zip,
            city: values?.city,
            country: values?.country?.label,
            state: values?.state?.label
        }
        if (formType === 'Add') {
            setIsSaving(true)
            try {
                const res = await addAddressDetails(data)
                if (res.data) {
                    success('Address added succesfully')
                    refetch()
                    form.resetFields()
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message)
                }
            } catch (error) {
                errorMessage('An unexpected error occurred')
            } finally {
                setIsSaving(false)
            }
        } else {
            setIsEditSaving(true)
            try {
                const res = await upDateAddressDetails({ data: data, addressId })
                if (res.data) {
                    success('Address updated succesfully')
                    refetch()
                    form.resetFields()
                    setIsModalOpen(false);
                } else {
                    errorMessage(res.error.data.message)
                }
            } catch (error) {
                errorMessage('An unexpected error occurred')
            } finally {
                setIsEditSaving(false)
            }
        }


    };
    const handleConfirm = async (id) => {
        try {
            const res = await deleteAddressDetails(id)
            if (res.data) {
                success(res.data.message)
                refetch()
            } else {
                errorMessage(res.error.data.message)
            }
        } catch (error) {
            errorMessage('An unexpected error occurred')
        }

    };

    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };

    const columns = [
        {
            title: 'S.No.',
            dataIndex: 'serial',
            key: 'serial',
            render: (text, record, index) => {
                const startSerial = (currentPage - 1) * pageSize + 1;
                return startSerial + index;
            },
        },
        {
            title: 'Address',
            render: (data) => (
                `${data?.address},${data?.country},${data?.city},${data?.state},${data?.billing_zip}`
            ),
        },

        {
            title: 'Options',

            render: (data) => (

                <div className="buttonWrap">
                    <button type="button" className="btn optionBtn me-2" onClick={() => { showModal(data); setAddressId(data?.id); setFormType('Edit') }}>Edit</button>
                    <button type="button" className="btn optionBtn" onClick={() => {
                        swal({
                            title: "Warning",
                            text: "Are you sure want to delete the address?",
                            icon: "warning",
                            dangerMode: true,
                        })
                            .then(ok => {
                                if (ok) {
                                    handleConfirm(data?.id)
                                }
                            })
                    }}>Delete</button>
                </div>
            ),
        },
    ];


    return (
        <div className="billingWrapper">
            <div className="card accountCard h-100">
                {contextHolder}
                <div className="card-header">
                    <h4 className="card-title">Billing Information</h4>
                    <button type="button" className="btn editButton" onClick={() => { showModal(); setFormType('Add') }}>Add Address</button>
                </div>
                <div className="tableWrapper table-responsive">
                    <Table
                        className="billingWrap"
                        dataSource={userDetail?.data?.billing_addresses?.map(item => ({ ...item, key: item.id }))}
                        columns={columns}
                        pagination={{
                            pageSize,
                            current: currentPage,
                            onChange: page => setCurrentPage(page),
                        }}
                    />
                </div>
            </div>
            <Modal
                title={`${formType} Address Details`}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleOk}
                className="formCard"
                centered
                footer={null}
                okText="Update"
            >
                <div className="modalBody">
                    <Form
                        form={form}
                        name="billingForm"
                        layout="vertical"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <div className="row">
                            <div className="col-md-12">
                                <Form.Item
                                    name="address"
                                    label="Address"

                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  address!",
                                        },
                                        {
                                            pattern: /^[a-zA-Z0-9\s,'-./#]+$/,
                                            message: "Please enter a valid address",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Address" maxLength={255} />
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="country"
                                    label="Country"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  country",
                                        },

                                    ]}
                                >

  <Select
                                        showSearch
                                        placeholder="Select Country"
                                        labelInValue
                                        onChange={handleCountryChange}
                                        value={selectedCountry}
                                        className="country-select"
                                    >
                                        {countries.map((country) => (
                                            <Option key={country.isoCode} value={country.name} className="d-flex">
                                                 {country.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="state"
                                    label="State"
                                    rules={[{ required: true, message: "Please input your current state" }]}
                                >
                                    <Select
                                        showSearch
                                        placeholder="Select State"
                                        labelInValue
                                        onChange={handleStateChange}
                                        value={selectedState}
                                      
                                    >
                                        {state.map((state) => (
                                            <Option key={state.isoCode} value={state.name}>
                                                {state.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>

                            <div className="col-md-12">
                                <Form.Item
                                    name="city"
                                    label="City"
                                    rules={[{ required: true, message: "Please input your current city" }]}
                                >
                                 <Select
                                        showSearch
                                        placeholder="Select City"
                                        onChange={handleCityChange}
                                        value={selectedCity}
                                      
                                    >
                                        {Cities.map((city) => (
                                            <Option key={city.isoCode} value={city.name}>
                                                {city.name}
                                            </Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-12">
                                <Form.Item
                                    name="billing_zip"
                                    label="ZIP"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your current  zip",
                                        },
                                        {
                                            pattern: /^[0-9]{5,10}$/,
                                            message: "Please input valid zipcode",
                                        },

                                    ]}
                                >
                                    <Input className="form-control" placeholder="Zip" type="number" />
                                </Form.Item>
                            </div>
                            <div className="buttonWrapper">
                                <Button htmlType="submit" className="submitBtn me-4" disabled={formType == 'Add' ? isSaving : isEditSaving}>Save</Button>
                                <button type="button" className="btn cancelBtn" onClick={handleOk}>Cancel</button>
                            </div>
                        </div>
                    </Form>
                </div>
            </Modal>

        </div>
    );
};



export default BillingInformation;