import React, { useState, useEffect } from "react";
import { Survey, Model as SurveyModel } from 'survey-react';
import "survey-core/defaultV2.css";
import FeaturedSection from "../../components/features/features";
import BannerSection from "./banner";
import CTA from "../../components/cta/cta";
import "./survey.scss";
import { Modal, Spin } from 'antd';
import axios from "axios";
import { useSurveyStatusMutation } from "../../store/apiSlice";
import { useGetSurveyStatusQuery } from "../../store/getSlice";
import { UAParser } from 'ua-parser-js'; // Fix import

const Homepage = () => {
    const [isPopupVisible, setIsPopupVisible] = useState(false);
    const [surveyModel, setSurveyModel] = useState(null);
    const [surveyStatus, setSurveyStatus] = useState(false);
    const [sessionUuid, setSessionUUId] = useState();
    const [flowDetails, setFlowDetails] = useState();
    const [flowSettings, setFlowSettings] = useState();
    const [addSurveyStatus] = useSurveyStatusMutation();
    const [loading, setLoading] = useState(true); // Add loading state
    const { data: getSurveyStatus, isLoading: isLoading  } = useGetSurveyStatusQuery();
    const parser = new UAParser(); // Use UAParser as expected
    let FlowUUID;
    let SessionUUIDValue;

    useEffect(() => {
        const timer = setTimeout(() => setIsPopupVisible(true), 1000); // 1-second delay
        return () => clearTimeout(timer); // Clean up timer on unmount
    }, []);

    useEffect(() => {
        axios.get(`https://sunbird-staging.aleaspeaks.com/api/v1/convert-to-surveyJs?flow_id=165`)
            .then(response => {
                const model = new SurveyModel(response?.data);
                model.completeText = "Next"; // Change this to your desired text
                model.onValueChanged.add(handleSurveyValueChanged);
                model.onComplete.add(surveryComplted)
                setSurveyModel(model);

                axios.get(`https://sunbird-staging.aleaspeaks.com/api/v1/flow/165`)
                    .then((res) => {
                        setFlowDetails(res.data.data);
                        const uaResult = parser.getResult(); // Retrieve parsed user-agent details
                        let affiliateId = res.data.data?.affiliate_id;
                        FlowUUID = res?.data?.data?.flow_uuid;
                        setFlowSettings(JSON.parse(res.data.data.settings));
                        const data = {
                            flow_id: 165,
                            traffic_source: "c13",
                            affiliate_id: affiliateId,
                            ua: uaResult.ua, // No need to parseInt, this is a string
                            browser_name: uaResult.browser?.name || null,
                            browser_version: uaResult.browser?.version || null,
                            browser_major: uaResult.browser?.major || null,
                            cpu_architecture: uaResult.cpu?.architecture || null,
                            device_model: uaResult.device?.model || null,
                            device_vendor: uaResult.device?.vendor || null,
                            engine_name: uaResult.engine?.name || null,
                            engine_version: uaResult.engine?.version || null,
                            os_name: uaResult.os?.name || null,
                            os_version: uaResult.os?.version || null,
                        };

                        axios.post('https://sunbird-staging.aleaspeaks.com/api/v1/sid', data)
                            .then((response) => {
                                const sessionUUID = response?.data?.uuid;
                                localStorage.setItem("SessionuuiD", sessionUUID);
                                setSessionUUId(sessionUUID);
                                SessionUUIDValue = sessionUUID;
                            })
                            .catch((err) => {
                                console.error("Error posting user data:", err);
                            });
                    })
                    .catch((err) => {
                        console.error("Error fetching flow data:", err);
                    });
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const saveSurveyData = (survey) => {
        localStorage.setItem("my-survey", JSON.stringify(survey.data));
    };

    const handleSurveyValueChanged = (sender, options) => {
        const currentPage = sender.currentPage;
        const currentQuestion = currentPage.getQuestionByName(options.name);

        if (!currentQuestion) return;

        // Save the survey data locally or via API
        saveSurveyData(sender);

        if (currentQuestion.getType() === 'checkbox') {
            const selectedValues = options.value || [];
            const selectedChoices = currentQuestion.choices.filter(choice => selectedValues.includes(choice.value));
            const selectedTexts = selectedChoices.map(choice => choice.text);
        }

        if (currentQuestion) {
            const questionText = currentQuestion.title;
            let answer;

            if (currentQuestion.getType() === 'imagepicker') {
                const selectedChoice = currentQuestion.choices.find(choice => choice.value === sender.getValue(options.name));
                answer = selectedChoice?.jsonObj?.img_label || selectedChoice?.imageLink || 'DefaultImageLink';
            } else if (['radiogroup', 'dropdown'].includes(currentQuestion.getType())) {
                const selectedValues = [].concat(sender.getValue(options.name));
                const selectedChoices = currentQuestion.choices.filter(choice => selectedValues.includes(choice.value));
                answer = selectedChoices.map(choice => choice.text).join(', ');
            } else {
                answer = sender.getValue(options.name);
            }

            const storedData = JSON.parse(localStorage.getItem('params')) || {};

            const surveyResults = {
                flow_uuid: FlowUUID ? FlowUUID : flowDetails?.flow_uuid, // Replace with dynamic value if needed
                pageIndex: sender.pages.indexOf(currentPage),
                questionIndex: currentPage.questions.indexOf(currentQuestion),
                session_uuid: SessionUUIDValue ? SessionUUIDValue : sessionUuid,
                field_name: questionText,
                answer: answer,
                question_id: currentQuestion?.jsonObj?.question_id,
                last_node_id: storedData.nodeid,
            };

            axios.post("https://sunbird-staging.aleaspeaks.com/api/v1/create-post", surveyResults)
                .then(response => {
                    if (!sessionUuid) {
                        sessionUuid = response?.data?.session_uuid;
                        localStorage.setItem("SessionID", sessionUuid);
                    }
                    if (sender.isLastPage) {
                        localStorage?.removeItem("SessionID");
                        localStorage?.removeItem("my-survey");
                        sender.completeLastPage();
                    }
                })
                .catch(error => console.error("Error saving survey results:", error));
        }
    };

    const handleClosePopup = () => {
        setIsPopupVisible(false);
    };

    const surveryComplted = async () => {
        let data = {
            survey_id: 165,
            survey_status: true
        }
        const res = await addSurveyStatus(data)
        if (res) {
            const filterSurvey = getSurveyStatus?.find((item) => item.survey_id === data.survey_id);
            setSurveyStatus(filterSurvey?.survey_status)
            setIsPopupVisible(false);
        } else {
            setSurveyStatus(false)
        }
    }

    useEffect(() => {
        if (getSurveyStatus) {
            const filterSurvey = getSurveyStatus?.find((item) => item.survey_id === 165);
            setSurveyStatus(filterSurvey?.survey_status)
        } else {
            setSurveyStatus(false)
        }

    }, [getSurveyStatus])

    useEffect(() => {
        if (flowDetails !== undefined) {
            let flowSettings = JSON.parse(flowDetails.settings);
            if (surveyStatus) {
                applyLayoutStyles(flowSettings);
            } else {
                setLoading(false);
            }

        }
    }, [flowDetails, isLoading]);

    function applyLayoutStyles(settings) {
        const { layout } = settings;
        const surveyElement = document.querySelector(".phone-modal");
        if (surveyElement !== null) {
            if (layout === "model") {
                // For modal layout, center the survey on the screen
                surveyElement.style.position = "fixed";
                surveyElement.style.top = "50%";
                surveyElement.style.left = "50%";
                surveyElement.style.transform = "translate(-50%, -50%)";
                surveyElement.style.zIndex = "1000";
                setLoading(false);
            }

            if (layout === "Overlay") {
                // For overlay layout, position based on flow settings
                const position = settings.survey_position || "upper_right_corner"; // Ensure a fallback
                switch (position) {
                    case "upper_left_corner":
                        surveyElement.style.position = "absolute";
                        surveyElement.style.top = "0";
                        surveyElement.style.left = "0";
                        break;
                    case "upper_right_corner":
                        surveyElement.style.position = "absolute";
                        surveyElement.style.top = "0";
                        surveyElement.style.right = "0";
                        break;
                    case "lower_left_corner":
                        surveyElement.style.position = "absolute";
                        surveyElement.style.bottom = "0";
                        surveyElement.style.left = "0";
                        break;
                    case "lower_right_corner":
                        surveyElement.style.position = "absolute";
                        surveyElement.style.bottom = "0";
                        surveyElement.style.right = "0";
                        break;
                    default:
                        console.error("Invalid overlay position");
                        setLoading(false);
                }
            }
        } else {
            setLoading(false);
        }
    }

    return (
        <div className="homepageWrapper">
            <BannerSection />
            <FeaturedSection />
            <CTA />

            {loading ? <>
                <Spin tip="Loading..." />
            </> : !surveyStatus ? <Modal
                className="phone-modal"
                open={isPopupVisible}
                onCancel={handleClosePopup}
                footer={null}
                title="Help Us Improve Your Home Buying Experience"
                maskStyle={{
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent black background
                    backdropFilter: 'blur(5px)', // Blur effect for the background
                }}

                style={{
                    position: flowSettings?.layout === "model" ? "fixed" : "absolute", // Dynamically apply position
                    top: flowSettings?.layout === "model" ? "50%" : "auto", // Dynamic alignment
                    left: flowSettings?.layout === "model" ? "50%" : "auto",
                    right: flowSettings?.layout === "Overlay" ? (flowSettings?.survey_position === "upper_right_corner" ? "0" : "auto") : "auto",
                    transform: flowSettings?.layout === "model" ? "translate(-50%, -50%)" : "none", // Center the modal for model flowSettings?.layout
                    bottom: flowSettings?.layout === "Overlay" ? "0" : "auto", // Position at the bottom if overlay
                    zIndex: "1000",
                    padding: 0,
                    maxWidth: flowSettings?.layout === "model" ? '35vw' : '25vw',
                }}
            >
                <div className="popup-content" id="survey">
                    {surveyModel && (
                        <Survey model={surveyModel} />
                    )}
                </div>
            </Modal>
                : ''}
        </div>

    );
};

export default Homepage;
