import React, { useEffect, useState } from "react";
import { Form, Input, Select } from "antd";
import { Link } from "react-router-dom";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const CalculatePayment = ({ amount, type }) => {
    const [form] = Form.useForm();
    const [monthlyPayment, setMonthlyPayment] = useState(null);
    const [mortgagecalculation, setMortgageCalculation] = useState(null);
    const [purchasePrice, setPurchasePrice] = useState(amount);
    const [downPayment, setDownPayment] = useState(0);
    const [downPaymentPercent, setDownPaymentPercent] = useState(20);


    const calculateDownPaymentPercent = (dp, price) => {
        if (price && dp) {
            const percent = ((dp / price) * 100).toFixed(2);

            setDownPaymentPercent(percent);
        } else {
            setDownPaymentPercent(0);
        }
    };

    const onPriceChange = (e) => {
        const price = parseFloat(e.target.value);
        setPurchasePrice(price);
    };

    const onDownPaymentChange = (e) => {
        const dp = parseFloat(e.target.value);
        setDownPayment(dp);
        calculateDownPaymentPercent(dp, purchasePrice);
    };

    const handleChange = (value) => {
        console.log(`selected ${value}`);
    };

    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    const calculateMortgage = (values) => {
        const purchasePrice = parseFloat(values.price);

        const downPayment = parseFloat(values.dp);

        const loanAmount = purchasePrice - downPayment;

        const loanTerm = parseInt(values.loan_type);

        const monthlyInterestRate = values.annualInterestRate / 100 / 12;

        const numberOfPayments = loanTerm * 12;

        // Mortgage calculation formula

        const monthlyPayment =
            (loanAmount *
                monthlyInterestRate *
                Math.pow(1 + monthlyInterestRate, numberOfPayments)) /
            (Math.pow(1 + monthlyInterestRate, numberOfPayments) - 1);

        const totalPayableInterest = (monthlyPayment * numberOfPayments) - loanAmount;

        const firstMonthInterest = loanAmount * monthlyInterestRate;
        const firstMonthPrincipal = monthlyPayment - firstMonthInterest;

        setMonthlyPayment({
            total: monthlyPayment.toFixed(2),

            principal: firstMonthPrincipal.toFixed(2),

            interest: firstMonthInterest.toFixed(2),
        });
        setMortgageCalculation({
            total: (monthlyPayment * numberOfPayments).toFixed(2),

            principal: loanAmount.toFixed(2),

            interest: totalPayableInterest.toFixed(2),
        })
    };

    const onFinish = (values) => {
        calculateMortgage(values);
    };

    const chartOptions = {
        chart: {
            type: "pie",
        },

        title: {
            text: "Principal vs Interest Breakdown",
        },

        plotOptions: {
            pie: {
                innerSize: "70%",
                dataLabels: {
                    enabled: true,
                    format: "{point.name}: ${point.y:.1f}",
                },
            },
        },

        series: [
            {
                name: "Payment Breakdown",
                data: [
                    {
                        name: "Principal",
                        y: mortgagecalculation ? (parseFloat(mortgagecalculation.principal)) : 0,
                    },

                    {
                        name: "Interest",
                        y: mortgagecalculation ? parseFloat(mortgagecalculation.interest) : 0,
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        if (amount !== undefined && type !== 'Leased') {
            let downpaymentAmount = amount / 100 * 20;

            form.setFieldsValue({
                price: amount,
                dp: downpaymentAmount.toFixed(2),
                loan_type: '30',
                annualInterestRate: '6.64'
            });

            let values = {
                price: amount,
                dp: downpaymentAmount.toFixed(2),
                loan_type: '30',
                annualInterestRate: '6.64'
            }
            calculateMortgage(values);
        }
    }, [amount, form]);

    return (
        <div className="calculateWrapper">
            <div className="innerWrap">
                <p className="topText">Mortgage Calculator</p>
                <p className="subText">
                    For traditional mortgage, enter your home loan details and get
                    personalized quotes with today’s mortgage rates.
                </p>

                <div className="formWrapper">
                    <Form
                        name="PaymentForm"
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="vertical"
                        form={form}
                    >
                        <div className="row">
                            <div className="col-md-3">
                                <Form.Item
                                    label="Purchase Price"
                                    name="price"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your purchase price",
                                        },
                                    ]}
                                >
                                    <Input placeholder="$ 205,900" className="form-control" onChange={onPriceChange} />
                                </Form.Item>
                            </div>

                            <div className="col-md-3">
                                <Form.Item
                                    label="Down Payment"
                                    name="dp"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input your down payment",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="$ 205,900"
                                        className="form-control-addon"
                                        addonAfter={`${downPaymentPercent}%`}
                                        onChange={onDownPaymentChange}
                                    />
                                </Form.Item>
                            </div>

                            <div className="col-md-3">
                                <Form.Item
                                    label="Loan Tenure"
                                    name="loan_type"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Select the loan tenure",
                                        },
                                    ]}
                                >
                                    <Select
                                        className="form-control-check"
                                        onChange={handleChange}
                                        placeholder="Select loan tenure"
                                    >
                                        <Select.Option value="10">10 Year Fixed</Select.Option>
                                        <Select.Option value="15">15 Year Fixed</Select.Option>
                                        <Select.Option value="20">20 Year Fixed</Select.Option>
                                        <Select.Option value="30">30 Year Fixed</Select.Option>
                                    </Select>
                                </Form.Item>
                            </div>
                            <div className="col-md-3">
                                <Form.Item
                                    label="Annual Interest Rate"
                                    name="annualInterestRate"
                                    rules={[
                                        {
                                            required: true,

                                            message: "Please input your annual interest rate",
                                        },
                                    ]}
                                >
                                    <Input placeholder="e.g. 5.0" className="form-control" />
                                </Form.Item>
                            </div>

                            <div className="col-md-6 my-4 textAlign order2">
                                <button type="submit" className="btn accountBtn w-auto">
                                    Calculate
                                </button>
                            </div>

                            <div className="col-md-6 d-flex align-items-center justify-content-end">
                                {monthlyPayment && (
                                    <div className="result">
                                        <p className="estimateRow">
                                            Estimated Monthly Payment:{" "}
                                            <strong>${monthlyPayment.total}</strong>
                                        </p>
                                    </div>
                                )}
                            </div>

                            <div className="col-md-12">
                                {monthlyPayment && (
                                    <div className="result">
                                        <HighchartsReact
                                            highcharts={Highcharts}
                                            options={chartOptions}
                                        />
                                    </div>
                                )}
                            </div>

                            <div className="col-md-12">
                                <div className="lendersRow">
                                    <p>
                                        No Lenders found in your search criteria, Please search with
                                        different Loan details
                                    </p>

                                    <p>
                                        Disclosure : <strong>Rate data provided by</strong>{" "}
                                        <Link to={"/"} className="bottomLink">
                                            RateUpdate.com
                                        </Link>
                                        .
                                        <strong>
                                            {" "}
                                            Displayed by ICB, a division of Mortgage Research Center,
                                            NMLS #1907, Equal Housing Opportunity.
                                        </strong>
                                        Payments do not include taxes, insurance premiums or private
                                        mortgage insurance if applicable. Actual payments will be
                                        greater with taxes and insurance included.
                                        <Link to={"/"} className="bottomLink">
                                            Rate and product details.
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Form>
                </div>
            </div>
        </div>
    );
};

export default CalculatePayment;
