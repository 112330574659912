import React, { useEffect, useState } from "react";
import { Avatar, Pagination, Spin } from "antd";
import { IoCalendarOutline } from "react-icons/io5";
import Moment from "react-moment";
import {
    BlogImageOne,
} from "../../constant/images";
import { useGetBlogListQuery, useLazyGetBlogListQuery } from "../../store/getSlice";
import { NavLink, useNavigate } from "react-router-dom";

const BlogListing = () => {
    const [totalPage, setTotalPage] = useState()
    const navigate = useNavigate()
    const [currentPage, setCurrentPage] = useState(1);
    const [fetchBlogList,{ data: getBlogList, isLoading, }]  = useLazyGetBlogListQuery();
    useEffect(() => {
       
        fetchBlogList(currentPage)
            .unwrap()
            .then((res) => {
                if (res?.meta) {
                    setTotalPage(res.meta.total);
                }
            })
            .catch((error) => {
                console.error("Error fetching blogs:", error);
            });
    }, [currentPage]);

    
    const onPaginationChange = (page) => { 
        setCurrentPage(page);
    };

    const handleCardClick = (blog) => {
        navigate('/blog-details', { state: { data: blog?.id } });
    };
    
    return (
        <div className="listingWrapper">
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="titleSection">
                            <h5 className="topTitle">Blogs</h5>
                            <h3 className="mainTitle">Latest Blogs</h3>
                        </div>
                    </div>
                </div>
                <div className="blogListingWrap">
                    <div className="row">
                        {isLoading ? <div className="spinContainer">
                            <Spin size="large" />
                        </div> : getBlogList?.data?.map((blog, index) => {
                            return (
                                
                                <div className="col-md-4 mb-4" key={index}  onClick={()=>handleCardClick(blog)} 
                                style={{ cursor: 'pointer' }} >
                                    <div className="card blogCard" >
                                        <div className="cardImage">
                                            <img src={blog?.title_image ? `https://rainfrog-api.aleaspeaks.com/storage/${blog?.title_image}` : BlogImageOne} className="img-fluid" alt="blog" />
                                        </div>
                                        <div className="card-body">
                                            <div className="authorDetailsSection">
                                                <div className="name">
                                                    <Avatar
                                                        className="bannerAvatar"
                                                        src="https://img.freepik.com/free-photo/bohemian-man-with-his-arms-crossed_1368-3542.jpg?ga=GA1.1.701385504.1724656585&semt=ais_hybrid"
                                                    />
                                                    <h4 className="authorName">{blog?.author}</h4>
                                                </div>
                                                <div className="date">
                                                    <span className="icon"><IoCalendarOutline /></span>
                                                    <Moment format='MMMM DD, YYYY'>{blog?.created_at}</Moment>
                                                </div>
                                            </div>
                                            <div className="blogContent">
                                                <h3 className="blogTitle">{blog?.title}</h3>
                                                <div dangerouslySetInnerHTML={{ __html: blog?.short_description }} className="blogDesc"></div>
                                                <NavLink to="/blog-details" state={{ data: blog?.id }} className="readMoreLink">Read More</NavLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <div className="d-flex align-item-center justify-content-center">
                {totalPage > 9 && (
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage || 1} // Set controlled current page
                        total={totalPage}
                        pageSize={9}
                        onChange={onPaginationChange} // Trigger state change
                    />
                )}
            </div>
        </div>
    );
};

export default BlogListing;