import React from "react";

import { Empty, Spin } from "antd";
import { useGetPagesDetailQuery } from "../../store/loginRegisterSlice";
import { useSelector } from "react-redux";

const PrivacyPolicy = () => {
    const{data:getPrivacyPolicy,isLoading} = useGetPagesDetailQuery('privacy-policy')
    const state = useSelector((state)=>state?.persistedReducer?.banner)
    return (
        <>
          {isLoading? 
            <div className="spinContainer">
            <Spin size="large" />
        </div>: getPrivacyPolicy?.page && getPrivacyPolicy?.page?.status == 1?
            <div className="termsWrapper">
            <div className="pageHeader" style={{
            backgroundImage: state?.privacy_policy
              ? `url(https://rainfrog-api.aleaspeaks.com/storage/${state?.privacy_policy})`
              : `url('../../assets/images/contactUs.jpg')`,
          }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="pageHeading">                          
                                <h3>Privacy Policy</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="contentWrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card termsCard">
                                <div className="card-header">
                                    <h4 className="card-title">
                                    {getPrivacyPolicy?.page?.title}
                                    </h4>
                                </div>
                                <div className="card-body">
                                    <div className="textSection">
                                        <p className="title text-capitalize"> {getPrivacyPolicy?.page?.keywords}:</p>
                                        <p className="text">
                                        {getPrivacyPolicy?.page?.summary}
                                        </p>
                                        
                                        <div dangerouslySetInnerHTML={{ __html: getPrivacyPolicy?.page?.description}}></div>
                                       
                                    </div>
                               
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>: <div className="emptyContainer">
                    <Empty />
                </div>}
        </>
    
    );
};

export default PrivacyPolicy;