import React, { useEffect, useState } from "react";
import { message, Table, Tooltip, Alert } from "antd";
import { useChangeSubscriptionStatusMutation } from "../../store/apiSlice";
import { useDispatch } from "react-redux";
import {
    useGetRetryPaymentQuery,
    useGetUserDetailQuery,
    useLazyGetRetryPaymentQuery,
} from "../../store/getSlice";
import { userData } from "../../store/authSlice";
import Moment from "react-moment";
import swal from "sweetalert";
import moment from "moment";

const MembershipInformation = () => {
    const [changeStatus, { isLoading: isLoadingStatus }] =
        useChangeSubscriptionStatusMutation();
    const { data: getUserDetail, refetch ,isLoading:isUserDataLoading} = useGetUserDetailQuery();
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [retry, setRetry] = useState(false);
    const[fetchRetry,{ data: getRetryPayment ,isLoading:isLoadingRetry}]  = useLazyGetRetryPaymentQuery();
    const [messageApi, contextHolder] = message.useMessage();
    const currentDate = new Date();
    const nextPaymentDate = new Date(getUserDetail?.data?.next_payment_date);
    const success = (message) => {
        messageApi.open({
            type: "success",
            content: message,
        });
    };

    const errorMessage = (message) => {
        messageApi.open({
            type: "error",
            content: message,
        });
    };
    const capitalizeFirstLetter = (string) => {
        return string?.charAt(0)?.toUpperCase() + string?.slice(1);
    };

    const columns = [
        {
            title: "Plan Type",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (status) => capitalizeFirstLetter(status) || "N/A",
        },
        {
            title: "Next Payment",
            dataIndex: "next_payment",
            key: "next_payment",
            
        },
        {
            title: "Actions",
            dataIndex: "options",
            key: "options",
            render: (option) => (
                <Tooltip title={"Change your membership status"}>
                    <div className="buttonWrap">{isLoadingStatus || isLoadingRetry ? "loading" : option}</div>
                </Tooltip>
            ),
        },
    ];

    const onFinish = async () => {
        setIsSaving(true);
        let data = {
            status: getUserDetail?.data?.status === "active" ? "pause" : "active",
        };
        try {
            const res = await changeStatus(data);
            if (res.data) {
                success("Status changed succesfully");
                refetch();
                dispatch(userData({ userDetail: getUserDetail?.data }));
            } else {
                errorMessage(res.error.data.message);
            }
        } catch (error) {
            errorMessage("An unexpected error occurred");
        } finally {
            setIsSaving(false);
        }
    };
    const onRetry = async () => {
        setIsSaving(true);
    
        try {
            const response = await fetchRetry().unwrap();
            
            if (response) {
                success("Status changed successfully");
            }
            
            refetch();
        } catch (error) {
            errorMessage(error?.data?.message || "An error occurred");
        } finally {
            setIsSaving(false);
        }
    };

    const dataSource = [
        {
            key: "1",
            type: "Plan 1",
            status: getUserDetail?.data?.status,
            next_payment: (
                <Moment format="MM/DD/YYYY">
                    {getUserDetail?.data?.next_payment_date}
                </Moment>
            ),
            options: (
                <button
                    type="button"
                    className="btn optionBtn"
                    disabled={isSaving}
                    onClick={() => {
                        if (getUserDetail?.data?.status === "trail_expired" || getUserDetail?.data?.status === "inactive") {
                            onRetry()
                        } else {
                            {
                                if(getUserDetail?.data?.status === "active" )
                                {
                                    swal({
                                        title: "Warning",
                                        text: "Are you sure you want to pause your membership?",
                                        icon: "warning",
                                        dangerMode: true,
                                        buttons: ["Cancel", "OK"], // Adds a Cancel button
                                      }).then((ok) => {
                                        if (ok) {
                                          onFinish();
                                        }
                                      });
                                }else{
                                    onFinish();
                                }
                              
                              }
                        }
                    }}
                >
                    {getUserDetail?.data?.status === "trail_expired" ||
                        getUserDetail?.data?.status === "inactive"
                        ? "Retry"
                        : getUserDetail?.data?.status === "active"
                            ? " Pause"
                            : "Active"}
                </button>
            ),
        },
    ];

    useEffect(() => {
        refetch();
    }, [getRetryPayment]);

    // useEffect(() => {
    //     if ( getUserDetail?.data?.status === "trail_expired" || getUserDetail?.data?.status === "inactive") {
    //         setRetry(false);
    //     }
    // }, [getUserDetail?.data?.status === "trail_expired" || getUserDetail?.data?.status === "inactive"]);

    return (
        <>
            {contextHolder}
            <div className="card accountCard">
                <div className="card-header">
                    <h4 className="card-title">Membership Status</h4>
                </div>
                <div className="card-body">
                    <div className="tableWrapper table-responsive">
                        <Table
                            className="membershipTable"
                            dataSource={dataSource}
                            columns={columns}
                            pagination={false}
                        />
                    </div>
                    {getUserDetail?.data?.status === "trail_expired" ||
                        getUserDetail?.data?.status === "inactive" || currentDate > nextPaymentDate? (
                        <Alert
                            message="Payment Failed"
                            description="Please check your payment details or try again later."
                            type="error"
                            showIcon
                            className="mt-4"
                            closable
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default MembershipInformation;
