import { createApi} from "@reduxjs/toolkit/query/react";
import {  baseQueryWithReauth } from "../Helpers/authHelper";


export const apiSlice = createApi({
  reducerPath: "apiSlice",
  baseQuery: baseQueryWithReauth,
  endpoints: (builder) => ({
    showProperties: builder.mutation({
      query: (data) => ({
        url: "properties",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["Properties"],
    }),
    recentlyViewProperties: builder.mutation({
      query: (data) => ({
        url: "recently-viewed-property/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ViewProperties"],
    }),
    searchProperty: builder.mutation({
      query: (data) => {
   
        const params = new URLSearchParams();
        if (data?.myzip) params.append('myzip', data.myzip);
        if (data?.nearme) params.append('nearme', data.nearme);
        if (data?.current_page) params.append('page', data.current_page);
        if (data?.zip_code) params.append('zip_code', data.zip_code);
        if (data?.beds) params.append('beds', data.beds);
        if (data?.baths) params.append('baths', data.baths);
        if (data?.min_price) params.append('min_price', data.min_price);
        if (data?.max_price) params.append('max_price', data.max_price);
        if (data?.square_feet) params.append('square_feet', data.square_feet);
        if (data?.year_built) params.append('year_built', data.year_built);
        if (data?.property_type?.length) {
            params.append('property_type', data.property_type.join(','));
        }  
        if (data?.sort_by) params.append('sort_by', data.sort_by);
        if (data?.sort_order) params.append('sort_order', data.sort_order);

        return {
            url: `search-property?${params.toString()}`, 
            method: "POST",
        };
    },
    }),
    addFavourite: builder.mutation({
      query: (data) => ({
        url: "favorite_property/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddFavourite"],
    }),
    removeFavourite: builder.mutation({
      query: (data) => ({
        url: `favorite_property/delete/${data}`,
        method: "DELETE",

      }),
      invalidatesTags: ["RemoveFavourite"],
    }),
  
    changePassword: builder.mutation({
      query: (password) => ({
        url: "update-password",
        method: "PUT",
        body: password,
      }),
      invalidatesTags: ["ChangePassword"],
    }),
    filterProperty: builder.mutation({
      query: (data) => ({
        url: "filter-property",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["FilterProperty"],
    }),
    contactSupport: builder.mutation({
      query: (data) => ({
        url: "contact_support/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["ContactUs"],
    }),
    addCardDetails: builder.mutation({
      query: (data) => ({
        url: "card/add",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddCardDetails"],
    }),
    upDateCardDetails: builder.mutation({
      query: ({ data, cardId }) => ({
        url: `card/update/${cardId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UpdateCardDetails"],
    }),
    deleteCardDetails: builder.mutation({
      query: (id) => ({
        url: `card/delete/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: ["DeleteCardDetails"],
    }),
    addAddressDetails: builder.mutation({
      query: (data) => ({
        url: "billing_address",
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["AddAddress"],
    }),
    upDateAddressDetails: builder.mutation({
      query: ({ data, addressId }) => ({
        url: `billing_address/${addressId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["UpdateAddressDetails"],
    }),
    deleteAddressDetails: builder.mutation({
      query: (id) => ({
        url: `billing_address/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: ["DeleteAddressDetails"],
    }),
changeSubscriptionStatus:  builder.mutation({    
query: (body) => ({
        url: `user/change_subscription_status`,
        method: "POST",
        body:body
      }),
      invalidatesTags: ["SubscriptionStatus"],
    }),
    addZipCode: builder.mutation({
      query: ( data) => {
 
        return{
          url: `zip/add`,
          method: "POST",
          body:data
        }
       
      },
      invalidatesTags: ["zipCodeadd"],
    }),

    upDateZipCode: builder.mutation({
      query: ({ data, zipCodeId }) => {
 
        return{
          url: `zip/update/${zipCodeId}`,
          method: "PUT",
          body:data
        }
       
      },
      invalidatesTags: ["zipCodeUpdate"],
    }),
    deleteZipCode: builder.mutation({
      query: (id) => ({
        url: `zip/delete/${id}`,
        method: "DELETE",

      }),
      invalidatesTags: ["zipCodeDelete"],
    }),
    deleteAccount: builder.mutation({
      query: () => ({
        url: `delete-account`,
        method: "DELETE",

      }),
      invalidatesTags: ["DeleteAccount"],
    }),
    recentlyZipCode: builder.mutation({
      query: (data) => {
        if(data){
          return{
            url: `recent-search`,
            method: "POST",
            body:data
          }
        }
       
      },
      invalidatesTags: ["RecentZip"],
    }),
    surveyStatus: builder.mutation({
      query: (data) => {
          return{
            url: `survey`,
            method: "POST",
            body:data
          }
      
      },
      invalidatesTags: ["SurveyStatus"],
    }),
  }),

  tagTypes: ["Properties", "ViewProperties", "SearchProperty", "AddFavourite", "RemoveFavourite",  "ChangePassword", "FilterProperty", "ContactUs", "AddCardDetails", "UpdateCardDetails", "DeleteCardDetails", "AddAddress", "UpdateAddressDetails", "DeleteAddressDetails","SubscriptionStatus","zipCodeUpdate","zipCodeDelete","zipCodeadd","DeleteAccount","RecentZip","SurveyStatus"]
});

export const { useShowPropertiesMutation, useRecentlyViewPropertiesMutation, useSearchPropertyMutation, useAddFavouriteMutation, useRemoveFavouriteMutation, useChangePasswordMutation, useFilterPropertyMutation, useContactSupportMutation, useAddCardDetailsMutation, useDeleteCardDetailsMutation, useUpDateCardDetailsMutation, useAddAddressDetailsMutation, useUpDateAddressDetailsMutation, useDeleteAddressDetailsMutation,useChangeSubscriptionStatusMutation,useDeleteZipCodeMutation,useUpDateZipCodeMutation,useAddZipCodeMutation,useDeleteAccountMutation,useRecentlyZipCodeMutation,useSurveyStatusMutation} = apiSlice;
